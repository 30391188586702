// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import 'animate.css'; // Import animate.css for animations
import '@/assets/styles/main.scss'; // Import the SCSS variables file


loadFonts();

createApp(App).use(router).use(vuetify).mount('#app');
