  <!-- src/views/ContactPage.vue -->
  <template>
    <div>
      <h1>Contact Page</h1>
      <p>This is the contact page.</p>
    </div>
  </template>

  <script>
  export default {
    name: 'ContactPage',
  };
  </script>
