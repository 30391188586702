<template>
    <v-footer app color="primary" dark>
      <v-container>
        <v-row>
          <!-- Company Info Section - Apply order-md-last for larger screens and order-first for mobile -->
          <v-col cols="12" sm="4" class="footer-section order-md-first order-last">
            <h3 class="footer-title">SeriousDesign</h3>
            <p class="footer-text">
              Av. Real Audiencia 170303
              <br />
              Quito, Ecuador 🇪🇨
              <br />
              Phone: +593 99 600 4015
              <br />
              Email:
              <a href="mailto:info@seriousdesign.com" class="footer-link">info@seriousdesign.com</a>
            </p>
          </v-col>

          <!-- Quick Links Section -->
          <v-col cols="12" sm="4" class="footer-section">
            <h3 class="footer-title">Quick Links</h3>
            <ul class="footer-links">
              <li>
                <router-link to="/" class="footer-link" aria-label="Navigate to Home">Home</router-link>
              </li>
              <li>
                <router-link to="/about" class="footer-link" aria-label="Navigate to About">About</router-link>
              </li>
              <li>
                <router-link to="/services" class="footer-link" aria-label="Navigate to Services">Services</router-link>
              </li>
              <li>
                <router-link to="/contact" class="footer-link" aria-label="Navigate to Contact">Contact</router-link>
              </li>
            </ul>
          </v-col>

          <!-- Simplified Contact Form Section -->
          <v-col cols="12" sm="4" class="footer-section">
            <h3 class="footer-title">Contact Us</h3>
            <v-form v-model="valid" ref="form">
              <v-text-field
                v-model="email"
                label="Email"
                :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Email must be valid']"
                required
                dense
                outlined
                class="mb-2"
                aria-label="Email Address"
              ></v-text-field>

              <v-btn color="secondary" small @click="submitForm" aria-label="Submit Contact Form">Send</v-btn>
            </v-form>
          </v-col>
        </v-row>

        <!-- Divider -->
        <v-divider class="my-4"></v-divider>

        <!-- Copyright Section -->
        <v-row justify="center">
          <p class="footer-text">&copy; {{ currentYear }} SeriousDesign. All Rights Reserved.</p>
        </v-row>
      </v-container>
    </v-footer>
  </template>

  <script>
  export default {
    name: 'AppFooter',
    data() {
      return {
        email: '',
        valid: false,
        currentYear: new Date().getFullYear(), // Set current year
      };
    },
    computed: {
      isDarkTheme() {
        return this.$vuetify.theme.global.current === 'darkTheme';
      },
    },
    methods: {
      submitForm() {
        if (this.$refs.form.validate()) {
          // Placeholder for form submission logic
          alert('Thank you for reaching out! We will get back to you soon.');
          this.email = '';
          this.$refs.form.resetValidation();
        }
      },
    },
  };
  </script>
