<!-- src/App.vue -->
<template>
    <v-app>
        <!-- Header -->
        <AppHeader />

        <!-- Main content area that automatically adjusts for the header and footer -->
        <v-main>
            <v-container class="fill-height">
                <router-view />
            </v-container>
        </v-main>

        <!-- Footer -->
        <AppFooter />
    </v-app>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'

export default {
    name: 'App',
    components: {
        AppHeader,
        AppFooter,
    },
}
</script>
