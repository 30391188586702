// src/plugins/vuetify.js
import { createVuetify } from 'vuetify';
import 'vuetify/styles'; // Import Vuetify styles
import '@/assets/styles/variables.scss'; // Import our custom variables
import { aliases, mdi } from 'vuetify/iconsets/mdi'; // Using Material Design Icons
import '../assets/styles/variables.scss';

const vuetify = createVuetify({
	icons: {
		defaultSet: 'mdi',
		aliases,
		sets: {
			mdi,
		},
	},
	theme: {
		defaultTheme: 'light', // Set the default theme
		themes: {
			lightTheme: {
				primary: 'var(--v-primary-base)',
				secondary: 'var(--v-secondary-base)',
				accent: 'var(--v-accent-base)',
				error: 'var(--v-error-base)',
				info: 'var(--v-info-base)',
				success: 'var(--v-success-base)',
				warning: 'var(--v-warning-base)',
			},
			darkTheme: {
				dark: true, // Enable dark mode
				primary: 'var(--v-dark-primary-base)',
				secondary: 'var(--v-dark-secondary-base)',
				background: 'var(--v-dark-background-base)',
				accent: 'var(--v-dark-accent-base)',
				error: 'var(--v-dark-error-base)',
				info: 'var(--v-info-base)',
				success: 'var(--v-success-base)',
				warning: 'var(--v-warning-base)',
			},
		},
	},
	defaults: {
		VBtn: {
			color: 'primary',
			rounded: true,
			elevation: 2,
		},
		VTextField: {
			variant: 'outlined',
			density: 'compact',
		},
	},
	breakpoint: {
		thresholds: {
			xs: 480,
			sm: 768,
			md: 1024,
			lg: 1440,
		},
		scrollBarWidth: 24,
	},
});

export default vuetify;
