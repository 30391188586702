<!-- src/views/HomePage.vue -->
<template>
    <v-container fluid class="main-content py-5">
        <!-- Existing "Coming Soon" Section -->
        <v-row align="center" justify="center">
            <v-col cols="12" md="8" lg="6" class="text-center">
                <h2 class="main-content__title">Coming Soon</h2>
                <p class="main-content__description">
                    We carefully understand each project's purpose to provide
                    solutions that are technically effective, economically
                    feasible, and environmentally conscious.
                </p>
                <v-progress-circular
                    :size="100"
                    :width="15"
                    indeterminate
                    color="primary"
                    class="my-5"
                ></v-progress-circular>
            </v-col>
        </v-row>

        <!-- Services Section with Transition -->
        <transition name="fade">
            <div>
                <v-row align="center" justify="center" class="mt-5">
                    <v-col cols="12" md="8" class="text-center">
                        <h3
                            class="services__title animate__animated animate__fadeIn"
                        >
                            Our Services
                        </h3>
                        <p
                            class="services__description animate__animated animate__fadeIn"
                        >
                            At SeriousDesign, we offer a range of engineering
                            solutions tailored to your project’s unique
                            requirements.
                        </p>
                    </v-col>
                </v-row>

                <v-row align="center" justify="center">
                    <!-- Service Card 1 -->
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        class="mb-4 animate__animated animate__zoomIn"
                    >
                        <v-card class="service-card">
                            <v-card-title>
                                <v-icon large color="primary" class="mr-2"
                                    >mdi-cpu-64-bit</v-icon
                                >
                                Custom Electronics Design
                            </v-card-title>
                            <v-card-text>
                                We design custom electronics solutions for a
                                variety of applications, focusing on innovation
                                and reliability.
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Service Card 2 -->
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        class="mb-4 animate__animated animate__zoomIn"
                    >
                        <v-card class="service-card">
                            <v-card-title>
                                <v-icon large color="primary" class="mr-2"
                                    >mdi-code-braces</v-icon
                                >
                                Software Development
                            </v-card-title>
                            <v-card-text>
                                Our software development services bring your
                                ideas to life with cutting-edge technologies and
                                agile methodologies.
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- Service Card 3 -->
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        class="mb-4 animate__animated animate__zoomIn"
                    >
                        <v-card class="service-card">
                            <v-card-title>
                                <v-icon large color="primary" class="mr-2"
                                    >mdi-lightbulb-outline</v-icon
                                >
                                Technical Consulting
                            </v-card-title>
                            <v-card-text>
                                We provide expert consulting to help you
                                navigate complex engineering challenges and find
                                effective solutions.
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </transition>
    </v-container>
</template>

<script>
export default {
    name: 'HomePage',
}
</script>