<template>
    <v-app-bar :color="isDarkTheme ? 'primary' : 'secondary'" :dark="isDarkTheme" app>
      <!-- Mobile Menu Icon: Only visible on small screens -->
      <v-app-bar-nav-icon @click="toggleDrawer" class="d-md-none"></v-app-bar-nav-icon>

      <!-- Navigation Title -->
      <v-toolbar-title class="mr-auto">SeriousDesign</v-toolbar-title>

      <!-- Desktop Navigation Links: Hidden on mobile devices -->
      <v-spacer></v-spacer>
      <v-btn text class="nav-btn d-none d-md-flex" to="/">Home</v-btn>
      <v-btn text class="nav-btn d-none d-md-flex" to="/about">About</v-btn>
      <v-btn text class="nav-btn d-none d-md-flex" @click="contactUs">Contact Us</v-btn>

      <!-- Theme Switch Button -->
      <v-btn icon @click="toggleTheme">
        <v-icon>{{ isDarkTheme ? 'mdi-weather-sunny' : 'mdi-weather-night' }}</v-icon>
      </v-btn>

      <!-- Mobile Navigation Drawer -->
      <v-navigation-drawer v-model="drawer" app temporary class="d-md-none">
        <v-list dense>
          <v-list-item @click="navigateAndClose('/')">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item @click="navigateAndClose('/about')">
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>
          <v-list-item @click="contactUs">
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-app-bar>
  </template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      drawer: false, // Controls the state of the navigation drawer
    };
  },
  computed: {
    isDarkTheme() {
      // Check if the current theme is 'darkTheme'
      return this.$vuetify.theme.global.name === 'darkTheme';
    },
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer; // Toggle the drawer's visibility
    },
    navigateAndClose(route) {
      this.$router.push(route); // Navigate to the specified route
      this.drawer = false; // Close the drawer after navigation
    },
    contactUs() {
      alert('Contact us at: info@seriousdesign.com');
      this.drawer = false; // Close the drawer if it's open
    },
    toggleTheme() {
      // Toggle the theme between 'light' and 'darkTheme'
      const newTheme = this.isDarkTheme ? 'lightTheme' : 'darkTheme';

      // Set the new theme directly
      this.$vuetify.theme.global.name = newTheme;

      // Update the data-theme attribute on the root element if needed
      document.documentElement.setAttribute('data-theme', newTheme === 'darkTheme' ? 'dark' : 'light');
    },
  },
};
</script>
