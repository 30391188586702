<!-- src/views/AboutPage.vue -->
<template>
    <v-container fluid class="about-page py-5">
        <!-- About Section -->
        <v-row align="center" justify="center">
            <v-col cols="12" md="8" lg="6" class="text-center">
                <h2 class="about-page__title">About SeriousDesign</h2>
                <p class="about-page__description">
                    SeriousDesign is an engineering solutions company dedicated
                    to providing innovative, effective, and sustainable
                    solutions. Our team of experts ensures that each project
                    meets technical, economic, and environmental considerations
                    with precision and care.
                </p>
            </v-col>
        </v-row>

        <!-- Mission Section -->
        <v-row align="center" justify="center" class="mt-5">
            <v-col cols="12" md="8" lg="6" class="text-center">
                <h3 class="about-page__subtitle">Our Mission</h3>
                <p class="about-page__description">
                    To deliver world-class engineering solutions by
                    understanding our clients' needs, leveraging technology, and
                    maintaining a commitment to quality, sustainability, and
                    efficiency.
                </p>
            </v-col>
        </v-row>

        <!-- Contact Section -->
        <v-row align="center" justify="center" class="mt-5">
            <v-col cols="12" md="8" lg="6" class="text-center">
                <h3 class="about-page__subtitle">Get in Touch</h3>
                <p class="about-page__description">
                    Interested in collaborating with us or learning more?
                    Contact us to discuss how SeriousDesign can bring your ideas
                    to life.
                </p>
                <v-btn
                    color="primary"
                    @click="contactUs"
                    class="about-page__contact-btn"
                >
                    Contact Us
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'AboutPage',
    methods: {
        contactUs() {
            alert('Contact us at: info@seriousdesign.com')
        },
    },
}
</script>